import React from 'react';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const BookingWrapper = styled.div`
    display: flex;
    display: -webkit-box;
    display:-webkit-flex;    
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: center;
    font-size: 0.8em;

    ${({ theme }) => theme.media.tablet}{
        font-size: 1.0em;        
        padding-bottom: 20px;
    }

    ${({ theme }) => theme.media.phone}{
        font-size: 0.7em;        
        padding-bottom: 20px;
    }
`

const Mail = styled.span`
    padding: 5px;
    text-decoration: none;
    color: white;

    ${({ theme }) => theme.media.phone}{
        font-size: 1.0em;
    }

    
    ${({ theme }) => theme.media.tablet}{
        font-size: 0.8em;
    }
`

const Phone = styled.span`
    padding: 5px;    
    text-decoration: none;
    color: white;

    ${({ theme }) => theme.media.phone}{
        font-size: 1.0em;
    }

    ${({ theme }) => theme.media.tablet}{
        font-size: 0.8em;
    }
`

const ContactInfo = () => (
    <BookingWrapper>
        <Mail onClick={e => trackCustomEvent({
            category: "Mail",
            action: "Click",
            label: "Mail address clicked"
        })} as="a" href="mailto:boguszmusic@gmail.com">boguszmusic@gmail.com</Mail>

        <Phone onClick={e => trackCustomEvent({
            category: "Phone",
            action: "Click",
            label: "Phone number clicked"
        })} as="a" href="tel:+48668283628">+48 668 283 628</Phone>
    </BookingWrapper>
)

export default ContactInfo;