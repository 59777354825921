import React from 'react';
import styled from 'styled-components';
import Menu from '../components/Menu/menu';
import Footer from '../components/Footer/footer';
import Layout from "../components/Layout/layout"
import image from '../assets/images/background2.jpg'
import NewsletterSubscription from '../components/Newsletter/NewsletterSubscription';
// import BackgroundImage from '../components/BackgroundImage/BackgroundImage'


import ContactForm from '../components/Contact/ContactForm'
import ContactInfo from '../components/Contact/ContactInfo';
import SocialMedia from '../components/SocialMedia/socialMedia';

const ContactWrapper = styled.div`
    display: -webkit-box;
    display:-webkit-flex;    
    -webkit-flex-direction: column;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: column;    
    background-image: ${({ image }) => `url(${image})`};
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
`;

const MenuWrapper = styled.div`
    display:flex;    
    flex-direction: column;
    display: -webkit-box;
    display:-webkit-flex;    
    -webkit-flex-direction: column;
    height: 10vh;
`

const ContentWrapper = styled.div`
    display: -webkit-box;
    display:-webkit-flex; 
    flex-direction:column;       
    -webkit-flex-direction: column;
    display:flex;    
    justify-content:center;    
    align-items:center;        
    height: 80vh;
    width: 100%;
`

const Content = styled.div`    
     display: -webkit-box;
    display:-webkit-flex;    
    display:flex;
    width: 70%;
    height: 75vh;
    overflow:auto;
    padding: 30px;
    background-color: rgba(0,0,0,0.8);
    color: white;
    align-items: stretch;
    -webkit-align-items: stretch;
    
    ${({ theme }) => theme.media.phone}{           
        display: block;
        width: 100%; 
        overflow:auto;
    }

    ${({ theme }) => theme.media.tablet}{                
        
        width: 100%; 
        overflow:auto;
    }
`

const FormWrapper = styled.div`
    display: flex;
    display:-webkit-flex;
    width:50%;
    align-content: center;
    -webkit-align-content: center;
    justify-content:center;
    -webkit-justify-content:center;
    align-items: center;
    -webkit-align-items: center;
    justify-items: center;
    -webkit-justify-items: center;

    ${({ theme }) => theme.media.phone}{
        width: 100%;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding: 0;
        padding-top: 3vh;        
    }

    ${({ theme }) => theme.media.tablet}{
        width: 100%;
        justify-content: space-between;        
        padding: 0;
        display: -webkit-box;
        display:-webkit-flex;    
        -webkit-flex-direction: column;
        flex-direction: column;
    }
`

const InfoWraper = styled.div`
    width:50%;
    display: flex;    
    display: -webkit-box;
    display:-webkit-flex;    
    -webkit-flex-direction: column;
    flex-direction: column;  
    justify-content: space-around;    
    justify-items: center;    
    align-content: center;    
    align-items: center;
    padding: 10vh;    
    
    ${({ theme }) => theme.media.phone}{
        width: 100%;        
        padding: 0;
        padding-top: 5vh;        
    }

    ${({ theme }) => theme.media.tablet}{
        width: 100%;
        justify-content: space-between;
        display: -webkit-box;
        display:-webkit-flex;    
        -webkit-flex-direction: column;
        flex-direction: column;
        padding-top: 2vh;
    }
`
const FooterWrapper = styled.div`

    ${({ theme }) => theme.media.phone}{
        display:none;
    }
`


const ContactPage = () => (
    <Layout>
        <ContactWrapper image={image}>
            <>
                <MenuWrapper>
                    <Menu showHome />
                </MenuWrapper>
                <ContentWrapper>
                    <Content>
                        <InfoWraper>
                            <ContactInfo />
                            <SocialMedia isWhite />
                            <NewsletterSubscription text={"Zapisz się do Newslettera, "} linkText={"kliknij tu!"} />
                        </InfoWraper>
                        <FormWrapper>
                            <ContactForm />
                        </FormWrapper>
                    </Content>
                </ContentWrapper>
                <FooterWrapper>
                    <Footer />
                </FooterWrapper>
            </>
        </ContactWrapper>
    </Layout>
)

export default ContactPage;