import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useStaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';


const Input = styled.input`
    margin: 5px;
    padding: 5px;
    height: 40px;
    width: 300px;    
    background-color: white;
    font-family: 'Krona One';    
    font-size: 0.4em;
    ${({ theme }) => theme.media.phone}{
            width: 100%;
    }

    ::placeholder{
        color: rgba(0,0,0,0.5);
        font-size: 0.8em;
    }
`

const TextArea = styled.textarea`
    margin: 5px;
    padding: 5px;
    width: 500px;
    height: 300px;
    resize: none;
    overflow: auto;
    background-origin:border-box;
    font-family: 'Krona One';    

    ${({ theme }) => theme.media.phone}{
            width: 100%;
    }

    ::placeholder{
        color: rgba(0,0,0,0.5);
        font-size: 0.8em;
    }
`

const Button = styled.button`
    padding: 5px;
    margin: 5px;
    align-self: flex-end;
    width: 100px;
    border-radius: 10px;
    border-color: white;
    font-family: 'Krona One';
    color: rgba(0,0,0,0.8);
    background-color: white;

    ${({ theme }) => theme.media.phone}{
        align-self: center;
        width: 250px;        
        height: 50px;
    }
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.media.phone}{
            width: 100%;
    }

    ${({ theme }) => theme.media.tablet}{
            width: 100%;
            justify-content: center;
            justify-items: center;
            align-content: center;
            align-items: center;
    }
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const SuccessInfo = styled.h5`
    color: green;
    font-size: 0.5em;
`

const ErrorInfo = styled.h5`
    color: red;
    font-size: 0.5em;
`

class ContactForm extends React.Component {

    constructor() {
        super();

        this.state = {
            name: '',
            email: '',
            message: '',
            submited: false,
            error: '',
            hasError: false
        }
    }

    onNameChange = (event) => {
        this.setState({ name: event.target.value })
    }

    onEmailChange = (event) => {
        this.setState({ email: event.target.value })
    }

    onMessageChange = (event) => {
        this.setState({ message: event.target.value })
    }

    submit = () => {

        if (this.state.name === '' || this.state.email === '' || this.state.message === '') {
            this.setState({ error: 'Wszystkie pola są wymagane!', hasError: true });
            return;
        }

        trackCustomEvent({
            category: "Contact Form",
            action: "Submit",
            label: "Contact Form Submit"
        })

        axios.post('https://bogusz-api.azurewebsites.net/api/contact/send', {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message
        }, {
            headers: {
                'Content-Type': 'application/json',
                'ApiKey': 'b6aa74d4-d9c3-412b-a2b7-d93a04be0a9f'
            }
        }).then(resp => {
            this.ResetState();
        }).catch(err => {
            if(err?.resp?.data != null){
                this.setState({ error: err.response.data, hasError: true })
            }
            else{
                this.setState({ error: 'Wystąpił błąd', hasError: true })
            }
            
        })
    }

    ResetState = () => {
        this.setState({
            name: '',
            email: '',
            message: '',
            submited: true,
            error: '',
            hasError: false
        })
    }

    render() {
        return (<Form >
            <FormWrapper>
                {this.state.submited ? <SuccessInfo>Wiadomosc zostala wyslana</SuccessInfo> : null}
                {this.state.hasError ? <ErrorInfo>{this.state.error}</ErrorInfo> : null}
                <Input type="text" name="name" placeholder="Imie" value={this.state.name} onChange={this.onNameChange} />
                <Input type="email" name="email" placeholder="E-mail" value={this.state.email} onChange={this.onEmailChange} />
                <TextArea name="message" placeholder="Wiadomość" value={this.state.message} onChange={this.onMessageChange} />
                <Button type="submit" onClick={this.submit} >Wyślij</Button>
            </FormWrapper>
        </Form>)
    }
}

export default ContactForm;