import React from 'react';
import styled from 'styled-components';
import Celebration from '../Icons/celebration'
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const NewslstterLink = styled.span`
    padding-left:10px;
    text-decoration: none;
    color: red;
    font-weight: 100;

    ${({ theme }) => theme.media.phone}{
            padding-left: 10px;
            font-size: 0.8em;
            
    }
`

const NewsletterSubscriptionWrapper = styled.div`
    display:flex;
    width: 100%;
    height: 15vh;
    margin-top: 20px;    
    align-items:center;    
    justify-content:center;
    font-size: 0.7em;

    ${({ theme }) => theme.media.tablet}{
        font-size: 0.7em;        
    }

    ${({ theme }) => theme.media.phone}{
        font-size: 0.6em;        
    }

`

const Text = styled.span`
    text-decoration: none;
    /* font-size: 0.8em; */
    ${({ theme }) => theme.media.phone}{            
        font-size: 0.8em;        
    }
`


const NewsletterSubscription = (props) => (
    <NewsletterSubscriptionWrapper>
        {props.showIcons ? <>
            <Celebration isWhite />
            <Celebration isWhite />
        </> : null}
        <Text>{props.text}</Text>
        <NewslstterLink onClick={e => {
            e.preventDefault()
            trackCustomEvent({
                category: "Newsletter",
                action: "Click",
                label: "From contact page",
                hitCallback: function () {
                    document.location = "/newsletter"
                }
            })
        }} as={Link} to="/newsletter">{props.linkText}</NewslstterLink>
        {props.showIcons ? <>
            <Celebration isWhite />
            <Celebration isWhite />
        </> : null}
    </NewsletterSubscriptionWrapper>
)

export default NewsletterSubscription;